import React from 'react'

import { Link } from "gatsby"

import '../styles/MainLinks.css'

const MainLinks = () => {
    return (
        <div className="main-links--container">
            <h3>Main Links</h3>
            <ul>
                <div className="main-links--bloq1">
                    <li><Link to="/projects">Projects</Link></li>
                    <li><Link to="/blog">Blog</Link></li>
                </div>
                <div className="main-links--bloq2">
                    <li><Link to="/biography">Biography</Link></li>
                    <li><a href="https://drive.google.com/file/d/1zO7eFEpTiMJNv_KFNGHaNho3OcyJmLIn/view?usp=sharing" target="_blank">Resume</a></li>
                </div>
            </ul>
        </div>
    )
}

export { MainLinks }
