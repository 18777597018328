import React from 'react'
import { Link } from "gatsby"

import logo from '../images/logos/Logo.png'

import '../styles/Rights.css'

const Rights = () => {
    return (
        <div className="rights--container">
            <Link to='/'>
                <div className="image--container">
                    <img src={logo} alt="Logo" />
                </div>
            </Link>
            <p>All rights reserved - 2022</p>
        </div>
    )
}

export { Rights }