import React from 'react'

import '../styles/Phrase.css'

const Phrase = () => {
    return (
        <div className="phrase--container">
            <p>"Science does not know about countries, because knowledge belongs to humanity and is the torch that illuminates the world. Science is the soul of the prosperity of nations and the source of all progress"</p>
            <p className='phrase-autor'>Louis Pasteur</p>
        </div>
    )
}

export { Phrase }