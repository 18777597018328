import React, { useState } from 'react'

import { Link } from 'gatsby'

import '../styles/Menu.css'

const Menu = () => {

    const [toggle, setToggle] = useState(true)

    const handleToggle = () => {
        setToggle(!toggle)
    }

    console.log(toggle)

    return (
        <>
            {toggle && 
                <div className="menu--container"
                onClick={handleToggle}>
                    <nav>
                        <Link to="/projects">Projects</Link>
                        <Link to="/blog">Blog</Link>
                        <Link to="/biography">Biography</Link>
                    </nav>
                </div>
            }
        </>
    )
}

export { Menu }