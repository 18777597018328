import React from 'react'

import facebook from '../images/icons/facebook.svg'
import twitter from '../images/icons/twitter.svg'
import linkedin from '../images/icons/linkedin.svg'
import github from '../images/icons/github.svg'

import '../styles/FollowMe.css'

const FollowMe = () => {
    return (
        <div className="follow-me--container">
            <h3>Contact Me:</h3>
            <div className="social-media--container">
                <a href="https://www.facebook.com/CaraveoJL" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="Facebook" /></a>
                <a href="https://twitter.com/JLCaraveo" target="_blank" rel="noopener noreferrer"><img src={twitter} alt="Twitter" /></a>
                <a href="https://www.linkedin.com/in/jlcaraveo/" target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="Linkedin" /></a>
                <a href="https://github.com/JLCaraveo" target="_blank" rel="noopener noreferrer"><img src={github} alt="Github" /></a>
            </div>
        </div>
    )
}

export { FollowMe }