import React, { useState } from 'react'
import { Link } from 'gatsby'

import { Menu } from '../components/Menu'

import '../styles/Header.css'

import logo from '../images/logos/Logo.png'
import menu_icon from '../images/icons/Menu.svg'

const Header = () => {
    const [toggle, setToggle] = useState(false)

    const handleToggle = () => {
        setToggle(!toggle)
    }

    console.log(toggle)

    return (
        <>
            <header >
                <div className="header-container main-table-container">
                    <Link to={"/"}>
                        <div className="logo-container">
                            <img src={logo} alt="Logo" />
                        </div>
                    </Link>
                    <div className="menu-container" onClick={handleToggle}>
                        <img src={menu_icon} alt="Menu_Icon" />
                    </div>
                    <div className="menu-nav">
                        <Link 
                        to={"/projects"}>Projects</Link>
                        <Link to={"/blog"}>Blog</Link>
                        <Link to={"/biography"}>Biography</Link>
                    </div>
                </div>
            </header>
            {toggle && <Menu />}
        </>
    )
}

export { Header }