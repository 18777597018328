import React from 'react'

/* import { Contact } from '@components/Contact' */
import { MainLinks } from '../components/MainLinks'
import { FollowMe } from '../components/FollowMe'
import { Phrase } from '../components/Phrase'
import { Rights } from '../components/Rights'

import '../styles/Footer.css'

const Footer = () => {
    return (
        <footer className='footer-container'>
            <div className="footer-content">
                {/* <Contact /> */}
                <div className="media-tablet">
                    <MainLinks />
                    <Phrase />
                    <div className="media-desktop">
                        <FollowMe />
                        <Rights />
                    </div>
                </div>
            </div>
        </footer>
    )
}

export { Footer }